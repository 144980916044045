<template class="pa-0">

<v-flex class="pa-0">

                  <v-card>
                    <v-card-title>
                       Resumen por vehiculos
                      <v-divider vertical class="mx-4"></v-divider>

                        <v-flex md2 lg2 mx-4 pt-5>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
                      </v-flex>

                       <v-btn dark color="success" @click="dialogInicio=true" class="mx-1">
                            <v-icon>date_range</v-icon>
                            </v-btn>


                       <vue-excel-xlsx  class="mx-1" v-show="data_excel.length >0"
                        :data="data_excel"
                        :columns="column_excel"
                        :filename="'Resumen_Paradas'"
                        :sheetname="'hoja1'">
                        <v-btn color="info">
                            <v-icon dark>get_app</v-icon>
                        </v-btn>
                        
                        </vue-excel-xlsx>

                        <v-spacer></v-spacer>

                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>


                    </v-card-title>


                    <v-data-table
                      :headers="headersResumenVeh"
                      :items="ItemResumenVeh"
                      :search="search"
                    >


                    <template v-slot:[`item.detalle`]="{ item }">
                                    <v-icon color="indigo" @click="AbrirDetalleVeh(item)">preview</v-icon>

                              </template>
                    
                    
                    </v-data-table>



                       <v-dialog v-model="dialogoVeh" max-width="850">
                    <v-card>

                      <v-toolbar color="red">
                        <v-toolbar-title class="white--text">  Detalle Paradas Vehiculo {{viewVeh.nombre}} - {{viewVeh.placa}}
                          
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                          <v-icon dark @click="dialogoVeh=false">close</v-icon>
                      
                      </v-toolbar>
                      

                      <v-card-text>


                        <v-card class="mt-5">
                              <v-card-title>
                                <v-text-field
                                  v-model="buscarVeh"
                                  append-icon="mdi-magnify"
                                  label="buscar"
                                  single-line
                                  hide-details
                                ></v-text-field>
                              </v-card-title>
                              <v-data-table
                                :headers="headersDetalleVeh"
                                :items="itemDetalleVeh"
                                :search="buscarVeh"
                              >
                              
                                  <template v-slot:[`item.fecha`]="{ item }">
                                    <span>{{ getDate(item.fecha) }}</span>
                              </template>

                                <template v-slot:[`item.ubicacion`]="{ item }">
                                  <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
                                </template>

                              </v-data-table>
                            </v-card>
                      
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn dark color="green darken-1" @click="dialogoVeh=false">
                          cerrar
                        </v-btn>

                      </v-card-actions>
                    </v-card>
                    </v-dialog>



                    
    <v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="fondo1" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Paradas por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>

           <v-flex>

                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="primary" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
   </v-dialog>

</v-card>


                  


   
                 
</v-flex>


</template>


<script>


import axios from 'axios';
import { mapMutations, mapState } from "vuex";

 export default {
    data: () => ({

        PaisesArray:[],
        id_pais_activo:'',
        search:'',

        headersResumenVeh:[ {text: 'Nombre', value: 'nombre' },
                                {text: 'Placa', value: 'placa' },
                                {text: 'Flota', value: 'flota' },
                                {text: 'paradas Autorizadas total', value: 'totalA' },
                                {text: 'paradas No Permitidas Total', value: 'totalN' },
                                {text: 'Detalle', value: 'detalle' }],


        ItemResumenVeh:[],



        dialogoVeh:false,
        viewVeh:{},


        buscarVeh:'',

        dialogInicio:false,

        menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),


         data_excel:[],

          column_excel:[ 
                    {label: "Vehiculo",  field: "vehiculo"},
                    {label: "Placa", field: "placa"},
                    {label: "Flota", field: "flota"},
                    {label: "paradas Autorizadas total",  field: "totalA"},
                    {label: "paradas No Permitidas total",  field: "totalN"},
                    ],


        headersDetalleVeh:[
                {text:'Fecha', value:'fecha'},{text:'Evento', value:'evento'},{text:'conductor', value:'conductor'},
                { text:'Geocerca', value:'Geocerca'}, {text:'Ubicacion', value:'ubicacion'},
                {text:'Direccion', value:'direccion'}

                ],

        itemDetalleVeh:[],


        ArrayParadas:[],
        resumen_vehiculos:[],
       


    }),

      computed: {

          ...mapState(['usuario', 'token']),
      },
      
      created () {
      this.listarPaises();
    },

    methods: {

         ...mapMutations(['mostrarLoading','ocultarLoading']),


           listarPaises () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             RespuestaPaises=response.data;

             RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

             me.listar();
            
              
          }).catch(function(error){
            console.log(error);
          });

         


           },

           listar(){

             this.ArrayParadas=[];
             this.data_excel=[];
           
               let config={headers:{ token:this.token}};
                let me=this;
                me.mostrarLoading({titulo:'listando datos'});

                  axios.post('paradas-all',{ 
                                'id_parada_pais':this.id_pais_activo,
                                'desde':this.fecha_inicio,
                                'hasta':this.fecha_fin,
                              },
                            config
                            ).then(response=>{
                            
                                console.log(response);
                                me.ArrayParadas=response.data;

                                 me.resumen_vehiculos=[];

                                me.ArrayParadas.map(function(x){
                                    
                                        let encontrado=false;
                                        me.resumen_vehiculos.map(function(y){

                                      
                                      if(x.id_vehiculo){
                                                    
                                                    if(x.id_vehiculo._id==y._id){

                                                          if(x.autorizado==true){
                                                                y.totalA++;
                                                            }else{
                                                                y.totalN++;
                                                            }


                                                             let geo_nombre='';
                                                            if(x.id_geocerca){
                                                                    geo_nombre=x.id_geocerca.nombre_geocerca
                                                            }



                                                      
                                                        let detalle=y.detalle;
                                                        detalle.push({'conductor':x.id_conductor.nombre_conductor, 
                                                                        'fecha':x.fecha, 
                                                                        'evento':x.evento, 'Geocerca':geo_nombre,
                                                                        'latitud':x.latitud, 'longitud': x.longitud,
                                                                        'direccion':x.direccion});

                                                        y.detalle=detalle;
                                                        encontrado=true;
                                                    }

                                            }
                                        });

                                        if((encontrado==false) &&(x.id_vehiculo)){

                                            let totalA=0;
                                            let totalN=0;


                                            if(x.autorizado==true){
                                                totalA++;

                                            }else{

                                                totalN++;

                                            }


                                             let geo_nombre='';


                                            if(x.id_geocerca){
                                                    geo_nombre=x.id_geocerca.nombre_geocerca
                                            }


                                            me.resumen_vehiculos.push({'_id':x.id_vehiculo._id,'nombre':x.id_vehiculo.nombre,
                                            'placa':x.id_vehiculo.placa,'flota':x.id_vehiculo.flota, 
                                            'totalA':totalA,'totalN':totalN,

                                             'detalle':[{'conductor':x.id_conductor.nombre_conductor, 'fecha':x.fecha, 
                                             'evento':x.evento, 'Geocerca':geo_nombre,
                                             'latitud':x.latitud, 'longitud': x.longitud,
                                             'direccion':x.direccion}]});
                                        }

                                });

                                 me.ItemResumenVeh=me.resumen_vehiculos;


                                 me.resumen_vehiculos.map(function(x){

                                   me.data_excel.push({
                                     'vehiculo':x.nombre,
                                     'placa':x.placa,
                                     'flota':x.flota,
                                     'totalA':x.totalA,
                                     'totalN':x.totalN
                                   });

                                 });


                                 me.ocultarLoading();
                                 me.dialogInicio=false;





                            }).catch(e=>{ console.log(e) });


           },



      

         AbrirDetalleVeh(item){

            this.viewVeh={'nombre':item.nombre,'placa':item.placa};

            console.log(item);

            this.itemDetalleVeh=item.detalle;
            this.dialogoVeh=true;          

      },

            ConvertirFecha(fecha_parametro){
          let texto=new Date(fecha_parametro).toLocaleDateString();
          return(texto);

      },


       getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
    },


        openGoogleMap(item){
        const urlSuffix = item.latitud +','+ item.longitud;
        window.open(
          "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
          "_blank"
        );
    }



    }
    
}
</script>